import React from 'react'
import { DefaultLayout } from '../components/Layout'
import { Jumbotron, Services } from '../components/Pages/Home'
import { CallBanner } from '../components/Banners'

const IndexPage = () => (
  <DefaultLayout title="Home">
    <Jumbotron />
    <CallBanner />
    <Services />
  </DefaultLayout>
)

export default IndexPage
