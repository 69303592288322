import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { LogoIcon } from '../../Icons'
import { Ellipsis } from '../../Typography'
import { ReportImage } from '../../Images'
import { Section } from '../../Layout'

const Image = styled.div`
  grid-column: 1/13;
  @media screen and (min-width: 1000px) {
    align-self: start;
    grid-column: 7/13;
    grid-row: 1/3;
  }
`
const Headers = styled.div`
  grid-column: 1/13;
  > * {
    margin-bottom: 0.3em;
  }
  @media screen and (min-width: 1000px) {
    align-self: end;
    grid-column: 1/7;
    grid-row: 1/2;
  }
`

const CTA = styled.div`
  grid-column: 1/13;
  @media screen and (min-width: 1000px) {
    align-self: start;
    grid-column: 1/7;
    grid-row: 2/3;
  }
`

const Button = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  padding: 1em 2.5em;
  box-sizing: border-box;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.success};
  color: ${props => props.theme.successText};
  transition: all 0.3s;

  :hover,
  :focus {
    background-color: #48a9a6;
    color: ${props => props.theme.white};
    transition: all 0.3s;
  }

  :active {
    background-color: ${props => props.theme.success};
    transition: all 0.3s;
  }
`

const Jumbotron = () => (
  <>
    <Section triangle="bottom-right">
      <Headers>
        <h1>
          Hazardous Area Services
          <Ellipsis />
        </h1>
        <h3>for DESEAR Compliance</h3>
        <LogoIcon width="30%" fill="#6A6A6A" />
      </Headers>
      <Image>
        <ReportImage alt="Image of Area Classification Report" />
      </Image>
      <CTA>
        <p>
          We aim to build strong relationships with our customers through cost-effective and adaptable mobilisation of
          your compliance needs. Our experts can offer accurate and reliable results, providing our customers with
          tangible actions and services to improve their sites' productivity, safety and efficiency.
        </p>
        <Button to="/services">Find Out More...</Button>
      </CTA>
    </Section>
  </>
)
export default Jumbotron
