import React from 'react'
import { Link as PageLink } from 'gatsby'
import styled from 'styled-components'
import { Section } from '../../Layout'
import { LogoIcon, RightIcon } from '../../Icons'
import { Ellipsis } from '../../Typography'

const Headers = styled.div`
  grid-column: 1/13;
  > * {
    margin-bottom: 0.3em;
  }
  @media screen and (min-width: 1000px) {
    align-self: end;
    grid-column: 1/7;
    grid-row: 1/2;
  }
`

const ServiceContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 2em;
  grid-column: 1/13;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  @media screen and (min-width: 1000px) {
    grid-gap: 5em;
    padding: 50px;
    div:nth-child(1) {
      grid-column: 1 /3;
    }
    div:nth-child(2) {
      grid-column: 3 /5;
    }
    div:nth-child(3) {
      grid-column: 1 /3;
    }
    div:nth-child(4) {
      grid-column: 3 /5;
    }
  }
`

const Service = styled.div`
  text-align: center;
  justify-content: center;
  position: relative;
  height: 10vh;
  min-height: 325px;
  min-width: 325px;
  border-radius: 30px;
  padding: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.white};
  grid-column: 1 / 5;
  transition: 0.5s;
  :hover {
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.medium};
    transition: 0.5s;
  }
  @media screen and (min-width: 1000px) {
    padding: 10vw;
  }
`

const Link = styled(PageLink)`
  display: flex;
  padding: 0.5em;
  position: absolute;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.white};
  bottom: 5%;
  right: 8%;
  svg {
    margin-left: 5px;
  }
`

const Services = () => {
  const happy = null
  return (
    <Section triangle="top-left">
      <Headers>
        <h1>
          Hazardous Area Services
          <Ellipsis />
        </h1>
        <h3>for DESEAR Compliance</h3>
        <LogoIcon width="30%" fill="#6a6a6a" />
        <p>
          Our aim is to build strong relationships with clients through cost effective compliance and dependable
          mobilisation coupled with accurate and reliable results. With our extensive knowledge and experience we can
          help clients comply with the DSEAR Regulations in a cost effective way and safeguard site safety. Click the
          boxes below to find out more information about our core services.
        </p>
      </Headers>
      <ServiceContainer>
        <Service>
          <h5>Initial inspections</h5>
          <Link to="/services">
            Find out More <RightIcon fill="white" width="1ch" />
          </Link>
        </Service>
        <Service>
          <h5>Area Classification</h5>
          <Link to="/services">
            Find out More <RightIcon fill="white" width="1ch" />
          </Link>
        </Service>
        <Service>
          <h5>Atex Inspections</h5>
          <Link to="/services">
            Find out More <RightIcon fill="white" width="1ch" />
          </Link>
        </Service>
        <Service>
          <h5>BS7671 Testing</h5>
          <Link to="/services">
            Find out More <RightIcon fill="white" width="1ch" />
          </Link>
        </Service>
      </ServiceContainer>
    </Section>
  )
}

export default Services
